
import {Component, Prop, Vue} from "vue-property-decorator";
import { AppealCustomFields } from "@/models/appeals";
import XDropdownWithDatePicker
  from "@/components/hoc/DropdownWithDatePicker.vue";
import DropdownWithDatePickerWithoutRange
  from "@/components/hoc/DropdownWithDatePickerWithoutRange.vue";
import TimePicker from "@/components/hoc/TimePicker.vue";
import DropdownWithRadioButtons
  from "@/components/hoc/DropdownWithRadioButtons.vue";
import XDropdownWithRadioButtons
  from "@/components/hoc/DropdownWithRadioButtons.vue";
import {appealsAcceptedExtensions} from "@/assets/scripts/utils";
import {FieldTypes} from "@/assets/scripts/custom-fields/field-types"

@Component({
  computed: {
    FieldTypes() {
      return FieldTypes
    },
  },
  components: {
    XDropdownWithRadioButtons,
    XDropdownWithDatePicker,
    DropdownWithDatePickerWithoutRange,
    TimePicker,
    DropdownWithRadioButtons,
  }
})
class CustomForm extends Vue {
  [x: string]: any

  @Prop({ default: "" }) readonly errorMessages!: string;
  @Prop({ required: true }) readonly item!: AppealCustomFields;

  showCustomInput = false;

  mounted() {
    this.item['значение'] = '';

    if(this.item['мультивыбор'] === 2 || this.item['мультивыбор'] === 3) {
      this.item['свойвариант'] = "";
    }
  }

  /**
   * Доступные расширения для файлов
   */
  getAppealsAcceptedExtensions() { return appealsAcceptedExtensions() }
  getAppealsAcceptedExtensionsText() { return 'Доступны форматы: ' + appealsAcceptedExtensions() }

  setValue(data) {
    this.item['значение'] = data;
  }

  setValueCheckbox(data) {
    this.item['значение'] = this.item['значение'] ?? "";

    if (this.item['значение'].split(';').find(elem => elem === data)) {
      this.item['значение'] = this.item['значение'].split(';').filter(el => el !== data).join(';');
    } else {
      this.item['значение'] += data + ';';
    }
  }

  setDateTime(data) {
    if(data['displayTime'] !== undefined) {
      this.item['значение-2'] = data['displayTime'] + ':00';
    } else {
      this.item['значение-1'] = data.split('T')[0].split('-').reverse().join('.');
    }
    this.item['значение'] = this.item['значение-1'] + ' ' + this.item['значение-2'];
  }

  onFileChanged(files: Array<File>) {
    this.item['значение'] = files;
  }

  setValueCustom() {
    this.showCustomInput = !this.showCustomInput;
  }

  isRequired() {
    return this.item['обязательное'] == 1;
  }

  customRule() {
    if (this.isRequired()) {
      return [
        v => !!v || 'Заполните данное поле',
      ];
    }
    return [];
  }
}

export default CustomForm;
