
import { Component, Prop, Vue } from "vue-property-decorator";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

/**
 * Пример использования
 *
 * <time-picker
 *   v-model="values[fieldName]"
 *   :error-messages="getErrors(fieldName)"
 *   @input="$v.values[fieldName].$touch()"
 *   @blur="$v.values[fieldName].$touch()"
 * />
 *
 * Описание поля
 *
 * {
 *   name: "time",
 *   attrs: {
 *     type: "time",
 *     label: "Время",
 *     required: true
 *   },
 *   rules: [required]
 * },
 */

@Component({
  components: { VueTimepicker },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get: function() {
        return this.modelValue
      },
      set: function(v) {
        let result = null;
        const HH = (v ? v['HH'] : "").replace(/^\D+/g, "");
        const mm = (v ? v['mm'] : "").replace(/^\D+/g, "");
        if (HH != "" && mm != "") {
          result = v['HH'] + ":" + v['mm'];
        }
        this.$emit('update:modelValue', result)
      }
    }
  }
})
class TimePicker extends Vue {
  [x: string]: any;

  @Prop({ default: [] }) readonly errorMessages!: any;
  @Prop({ default: false }) readonly hideDetails!: boolean;
}

export default TimePicker;
